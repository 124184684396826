import { LinksFunction, type MetaArgs, type MetaFunction } from '@remix-run/node'
import * as React from 'react'

import { type loader } from '~/routes/($lang)._index/index.server'
import { Container } from '@radix-ui/themes'
import { Welcome } from '~/routes/($lang)._index/welcome.tsx'
import { Services } from '~/routes/($lang)._index/services.tsx'
import { Jobs } from '~/routes/($lang)._index/jobs.tsx'
import { About } from '~/routes/($lang)._index/about.tsx'
import { Tips } from '~/routes/($lang)._index/tips.tsx'
import { metaDefault } from '~/utils/client/meta-default.ts'

/* -------------------------------------------------------------------------------------------------
 * I18NEXT
 * -----------------------------------------------------------------------------------------------*/

enum I18NEXT {
	routeIndex = 'route-_index',
}

/* -------------------------------------------------------------------------------------------------
 * ROUTE_LOADER
 * -----------------------------------------------------------------------------------------------*/

enum ROUTE_LOADER {
	root = 'root',
	current = 'routes/($lang)._index',
}

/* -------------------------------------------------------------------------------------------------
 * Handle
 * -----------------------------------------------------------------------------------------------*/

const handle = { i18n: [...Object.values(I18NEXT)] }

/* -------------------------------------------------------------------------------------------------
 * Links
 * -----------------------------------------------------------------------------------------------*/

const links: LinksFunction = () => {
  return [
    {
      rel: 'preload',
      href: '/pwa/compress/header_2.webp',
      as: 'image',
      type: 'image/webp',
    }, {
      rel: 'preload',
      href: '/pwa/compress/demolition_dismantling_work.webp',
      as: 'image',
      type: 'image/webp',
    }, {
      rel: 'preload',
      href: '/pwa/compress/flooring_work.webp',
      as: 'image',
      type: 'image/webp',
    }, {
      rel: 'preload',
      href: '/pwa/compress/screed_work.webp',
      as: 'image',
      type: 'image/webp',
    }, {
      rel: 'preload',
      href: '/pwa/compress/tile_work.webp',
      as: 'image',
      type: 'image/webp',
    }, {
      rel: 'preload',
      href: '/pwa/compress/painter_interior_decorator_work.webp',
      as: 'image',
      type: 'image/webp',
    }, {
      rel: 'preload',
      href: '/pwa/compress/masonry.webp',
      as: 'image',
      type: 'image/webp',
    }, {
      rel: 'preload',
      href: '/pwa/compress/plastering.webp',
      as: 'image',
      type: 'image/webp',
    }, {
      rel: 'preload',
      href: '/pwa/compress/drywall_construction_work.webp',
      as: 'image',
      type: 'image/webp',
    }, {
      rel: 'preload',
      href: '/pwa/compress/career.webp',
      as: 'image',
      type: 'image/webp',
    }, {
      rel: 'preload',
      href: '/pwa/compress/plumbing_work.webp',
      as: 'image',
      type: 'image/webp',
    }, {
      rel: 'preload',
      href: '/pwa/compress/project_coordination.webp',
      as: 'image',
      type: 'image/webp',
    }, {
      rel: 'preload',
      href: '/pwa/compress/leak_detection.webp',
      as: 'image',
      type: 'image/webp',
    }, {
      rel: 'preload',
      href: '/pwa/compress/drying.webp',
      as: 'image',
      type: 'image/webp',
    },
  ]
}

/* -------------------------------------------------------------------------------------------------
 * Meta
 * -----------------------------------------------------------------------------------------------*/

const meta: MetaFunction<typeof loader> = (props: MetaArgs) =>
  metaDefault({ ...props, ROUTE_LOADER, I18NEXT: {} })

/* -------------------------------------------------------------------------------------------------
 * Component
 * -----------------------------------------------------------------------------------------------*/

const Component: React.FC = () => {
	return (
    <div className="mb-20">
      <Welcome />
      <Container px="5">
        <Services />
        <Jobs />
        <About />
        <Tips />
      </Container>
    </div>
  )
}
Component.displayName = 'Component'

/* -----------------------------------------------------------------------------------------------*/

export {
  Component,
  handle,
  meta,
  links,
  I18NEXT as INDEX_I18NEXT,
  ROUTE_LOADER as INDEX_ROUTE_LOADER,
}
