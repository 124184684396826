import { Card, Flex, Heading, Inset, Separator, Text } from '@radix-ui/themes'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { INDEX_I18NEXT } from '~/routes/($lang)._index/index.component.tsx'
import { Image } from '~/components/ui/image.tsx'

const Services: React.FC = () => {
  const { t } = useTranslation(INDEX_I18NEXT.routeIndex)

  return (
    <section id="services" className="app-Services pt-20">
      <div className="flex flex-col items-center mt-12">
        <Heading as="h1" size="8" className="!text-accent-9">{t('common.services.our_services')}</Heading>
        <Heading as="h5" size="4" weight="regular" color="gray">{t('common.services.our_services_sub_1')}</Heading>
        <Separator orientation="horizontal" color="green" className="!bg-accent-9 mt-2 mb-6 !w-28 !h-1.5"/>
      </div>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {[
          { title: 'demolition_dismantling_work', img: 'demolition_dismantling_work.webp' },
          { title: 'flooring_work', img: 'flooring_work.webp' },
          { title: 'screed_work', img: 'screed_work.webp' },
          { title: 'tile_work', img: 'tile_work.webp' },
          { title: 'painter_interior_decorator_work', img: 'painter_interior_decorator_work.webp' },
          { title: 'masonry', img: 'masonry.webp' },
          { title: 'plastering', img: 'plastering.webp' },
          { title: 'drywall_construction_work', img: 'drywall_construction_work.webp' },
          { title: 'plumbing_work', img: 'plumbing_work.webp' },
          { title: 'leak_detection', img: 'leak_detection.webp' },
          { title: 'drying', img: 'drying.webp' },
          { title: 'project_coordination', img: 'project_coordination.webp' },
        ].map(({ title, img }) => (
          <Flex
            key={title}
            justify="center"
          >
            <Card
              size="3"
              className="text-center"
            >
              <Inset>
                <Image
                  className="grayscale brightness-100 hue-rotate-60"
                  src={`/pwa/compress/${img}`}
                  alt={t(`common.services.${title}_title`)}
                />
              </Inset>
              <Heading as="h2" size="7" className="text-center !text-accent-9 mt-9 mb-3">
                {t(`common.services.${title}_title`)}
              </Heading>
              <Text as="p" color="gray" className="text-center">
                {t(`common.services.${title}_description`)}
              </Text>
            </Card>
          </Flex>
        ))}
      </div>
    </section>
  )
}
Services.displayName = 'Services'

export {
  Services,
}
