import { Card, Heading, Text } from '@radix-ui/themes'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { INDEX_I18NEXT } from '~/routes/($lang)._index/index.component.tsx'
import parse from 'html-react-parser'

const Tips: React.FC = () => {
  const { t } = useTranslation(INDEX_I18NEXT.routeIndex)

  return (
    <section id="tips-and-tricks" className="app-Tips pt-20">
      <div className="flex flex-col items-center mt-12">
        <Heading size="8" className="!text-accent-9">- {t('common.tips.tips_and_tricks')} -</Heading>
        <Card className="mt-4" size="4">
          <>
            <Heading as="h3" color="gray" className="text-center mb-2">{t('common.tips.tips_and_tricks_1_title')}</Heading>
            <Text
              as="p"
              color="gray"
              className="whitespace-pre-line text-center"
            >
              {parse(t('common.tips.tips_and_tricks_1_sub'))}
            </Text>
            <ul className="grid md:grid-cols-2 gap-20 text-center mt-12">
              {[...Array(6).keys()].map((i) => (
                <li key={i} className="whitespace-pre-line">
                  <Text color="gray">
                    {parse(t(`common.tips.tips_and_tricks_1_item_${i}`))}
                  </Text>
                </li>
              ))}
            </ul>
          </>
        </Card>
      </div>
    </section>
  )
}
Tips.displayName = 'Tips'

export {
  Tips,
}
