import { Image } from '~/components/ui/image.tsx'
import { Card, Container, Heading, Text } from '@radix-ui/themes'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { INDEX_I18NEXT } from '~/routes/($lang)._index/index.component.tsx'

const Welcome: React.FC = () => {
  const { t } = useTranslation(INDEX_I18NEXT.routeIndex)

  return (
    <>
      <section className="w-full min-h-[50vh] h-40 overflow-hidden">
        <Image alt="header" src="/pwa/compress/header_2.webp" className="w-full overflow-hidden"/>
      </section>
      <Container px="4">
        <section id="about" className="flex justify-center">
          <Card size="4" className="max-w-4xl text-center -mt-16 shadow shadow-accent-4 hover:shadow-accent-4">
            <Heading as="h2" size="7" className="!text-accent-9">{t('common.welcome.welcome_0')}</Heading>
            <Heading as="h3" size="5" className="!text-accent-8 mt-4">{t('common.welcome.welcome_1')}</Heading>
            <Text as="p" color="gray" className="mt-7">{t('common.welcome.welcome_2')}</Text>
          </Card>
        </section>
      </Container>
    </>
  )
}
Welcome.displayName = 'Welcome'

export {
  Welcome,
}
