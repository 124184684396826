import { Image } from '~/components/ui/image.tsx'
import { Button, Card, Heading, Separator, Text } from '@radix-ui/themes'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { INDEX_I18NEXT } from '~/routes/($lang)._index/index.component.tsx'
import { Link } from '@remix-run/react'
import { VscTools } from 'react-icons/vsc'
import parse from 'html-react-parser'

const Jobs: React.FC = () => {
  const { t } = useTranslation(INDEX_I18NEXT.routeIndex)

  return (
    <section id="jobs" className="grid md:grid-cols-4 gap-12 pt-28 mt-12">
      <div className="inline-flex md:block justify-center">
        <Image alt="Jobs" src="/pwa/compress/career.webp" className="rounded shadow w-min max-w-xs md:max-w-full"/>
      </div>
      <div className="md:col-span-3">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex gap-x-4 my-3 justify-center md:justify-start">
            <Separator color="green" className="!bg-accent-9 !h-20 !w-1.5"/>
            <div className="flex flex-col mt-2">
              <Heading as="h5" size="4" color="gray" weight="regular">{t('common.jobs.we_have_the_jobs')}</Heading>
              <Heading as="h2" className="!text-accent-9">{t('common.jobs.and_need_you')}</Heading>
            </div>
          </div>
          <Button variant="outline" size="3">
            <Link
              to="/contact"
              prefetch="viewport"
              className="flex items-center gap-x-2"
            >
              <VscTools className="text-xl"/>
              {t('common.jobs.apply_now')}
            </Link>
          </Button>
        </div>
        <div className="my-4">
          <Text as="p" color="gray" className="whitespace-pre-line">{parse(t('common.jobs.work_1'))}</Text>
        </div>
        <div className="grid md:grid-cols-2 gap-12">
          <Card size="3" className="flex flex-col">
            <div>
              <Heading
                as="h4"
                className="!text-accent-7"
                size="5"
              >
                {t('common.jobs.we_are_looking_for_independent_craftsmen')}
              </Heading>
              <ul className="list-disc list-inside">
                {[
                  'painter',
                  'tiler',
                  'drywall_construction',
                  'installers',
                ].map((title) => (
                  <li key={title}>
                    <Text color="gray">
                      {t(`common.jobs.${title}`)}
                    </Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-10">
              <Heading
                as="h4"
                className="!text-accent-7"
                size="5"
              >
                {t('common.jobs.we_offer')}
              </Heading>
              <ol className="list-decimal list-inside">
                {[
                  'secure_order_situation',
                  'fair_fast_payment',
                  'generation_of_own_customers',
                  'general_advice_for_insurance',
                  'negotiable_order_amount',
                ].map((title) => (
                  <li key={title}>
                    <Text color="gray">
                      {t(`common.jobs.${title}`)}
                    </Text>
                  </li>
                ))}
              </ol>
            </div>
          </Card>

          <Card size="3" className="flex flex-col">
            <div>
              <Heading
                as="h4"
                className="!text-accent-7"
                size="5"
              >
                {t('common.jobs.we_are_looking_for_full_time_part_time')}
              </Heading>
              <ul className="my-3 ml-2">
                {[
                  'rehabilitation_specialist',
                  'craft_all_rounder',
                ].map((title) => (
                  <li key={title} className="font-bold">
                    <Text color="gray">
                      {t(`common.jobs.${title}`)}
                    </Text>
                  </li>
                ))}
              </ul>
              <ul className="list-disc list-inside">
                {[
                  'driving_licence_class_b',
                  'desire_for_craftsmanship',
                  'work_efficiently',
                  'ability_to_plan',
                  'customer_oriented_communication',
                  'preferably_experience_in_the_construction_industry',
                ].map((title) => (
                  <li key={title}>
                    <Text color="gray">
                      {t(`common.jobs.${title}`)}
                    </Text>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-10">
              <Heading
                as="h4"
                className="!text-accent-7"
                size="5"
              >
                {t('common.jobs.we_offer')}
              </Heading>
              <ol className="list-decimal list-inside">
                {[
                  'above_standard_pay',
                  '13th_monthly_salary_christmas_bonus',
                  'company_provided_construction_site_vehicle',
                  'varied_activities',
                  'retirement_provision_through_real_estate',
                  'capital_formation_benefits',
                ].map((title) => (
                  <li key={title}>
                    <Text color="gray">
                      {t(`common.jobs.${title}`)}
                    </Text>
                  </li>
                ))}
              </ol>
            </div>
          </Card>
        </div>
      </div>
    </section>
  )
}
Jobs.displayName = 'Jobs'

export {
  Jobs,
}
