import { Heading, Text } from '@radix-ui/themes'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { INDEX_I18NEXT } from '~/routes/($lang)._index/index.component.tsx'

const About: React.FC = () => {
  const { t } = useTranslation(INDEX_I18NEXT.routeIndex)

  return (
    <section id="about-us" className="pt-28">
      <div className="flex justify-center">
        <Heading size="8" className="!text-accent-9">- {t('common.about.about_us')} -</Heading>
      </div>
      <div>
        <ul className="mt-8 flex flex-col gap-y-5">
          {[...Array(5).keys()].map((i) => (
            <li key={i}>
              <Text as="p" color="gray">
                {t(`common.about.about_${i}`)}
              </Text>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
About.displayName = 'About'

export {
  About,
}
